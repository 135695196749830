// 爆料接口
import request from '@/utils/request'

// 查询爆料分类
export function getClassification(params) {
    return request({
        url: `/v2/api/app/ad_other//ugc/select/ugcTypeListWeb`,
        method: 'post',
        params: params
    })
}

// 查询分类爆料
export function getAllDynamic(params) {
    return request({
        url: `/v2/api/app/ad_other//ugc/select/ugcApp`,
        method: 'get',
        params: params
    })
}

// 上传爆料的图片、视频、音频
export function upLoadImg(data, params) {
    return request({
        url: `/v2/api/app/ap_media//app/mediaManage/uploadSimpleMedia`,
        method: 'post',
        data: data,
        params: params
    })
}

// 发布爆料
export function addNewInformation(data, params) {
    return request({
        url: `/v2/api/app/ad_other//ugc/add_or_update/ugcApp`,
        method: 'post',
        data: data,
        params: params
    })
}

// 根据分类Id查询分类详情
export function getClassDetail(params) {
    return request({
        url: `/v2/api/app/ad_other//ugc/select/ugcTypeId`,
        method: 'get',
        params: params
    })
}

// 新增爆料评论
export function addComment(data, params) {
    return request({
        url: `/v2/api/app/ap_member//ApUGCComment/releaseComment`,
        method: 'post',
        data: data,
        params: params
    })
}

// 查询爆料评论
export function getComment(params) {
    return request({
        url: `/v2/api/app/ap_member//ApUGCComment/getUGCCommentPage`,
        method: 'post',
        params: params
    })
}

// 根据Id查询爆料详情
export function getDetailById(params) {
    return request({
        url: `/v2/api/app/ad_other//ugc/select/ugcId`,
        method: 'get',
        params: params
    })
}

// 根据爆料话题
export function getTopics(params) {
    return request({
        url: `/v2/api/app/ad_other//ugc/select/ugcTypeListWeb`,
        method: 'post',
        params: params
    })
}

export default { getClassification, getAllDynamic, upLoadImg, addNewInformation, getClassDetail, addComment, getComment, getDetailById, getTopics }