import request from '@/utils/request'

// 会员签到
export function addSignIn(data) {
  return request({
    url: `/v2/api/app/ad_user//score/add/score`,
    method: 'post',
    data,
  })
}

// 查询积分
export function getScore(params) {
  return request({
    url: `/v2/api/app/ap_member//member/query/member/score?page=${params.page}&size=${params.size}&memberId=${params.memberId}`,
    method: 'get',
    params,
  })
}

// 查询志愿时长
export function getTime(params) {
  return request({
    url: `/v2/api/app/ap_member//member/query_server_time_page?page=${params.page}&size=${params.size}&memberId=${params.memberId}`,
    method: 'get',
    params,
  })
}

// 查询证书类型
export function getBookClass(params) {
  return request({
    url: `/v2/api/app/ap_member//app/Certificate/getCertificateTypeList`,
    method: 'post',
    params,
  })
}

// 提交证书申请
export function addBook(params) {
  return request({
    url: `/v2/api/app/ap_member//app/Certificate/applyCertificateType`,
    method: 'post',
    params,
  })
}

// 修改证书，重新申请
export function modifyBook(params) {
  return request({
    url: `/v2/api/app/ap_member//app/Certificate/updateCertificate`,
    method: 'post',
    params,
  })
}

// 查询已提交的证书申请列表
export function getBookList(params) {
  return request({
    url: `/v2/api/app/ap_member//app/Certificate/myCertificate`,
    method: 'post',
    params,
  })
}
// 判断是否是志愿者
export function getVolunteer(params) {
  return request({
    url: `/v2/api/app/ap_member//member/sense_volunteer`,
    method: 'get',
    params,
  })
}
// 不是志愿者注册
export function signVolunteer(data) {
  return request({
    url: `/v2/api/app/ap_member//member/add_volunteer/second_phase`,
    method: 'post',
    data,
  })
}
//加载所有特长
export function specialityMes() {
  return request({
    url: `/v2/api/app/ap_member//member/add_volunteer/second_phase`,
    method: 'get',
  })
}
export default {specialityMes,signVolunteer,getVolunteer, addSignIn, getScore, getTime, getBookClass, addBook, getBookList, modifyBook }